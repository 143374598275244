import {
  useCallback,
  useEffect,
  useState,
  useImperativeHandle,
  forwardRef,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { Document, Page } from 'react-pdf';
import { PDFDocument } from 'pdf-lib';
import { useToasts } from 'react-toast-notifications';
import moment from 'moment';

import { useViewport } from 'hooks/useViewport';
import { usePDFContex } from 'hooks/usePDFContex';
import Styled from 'types/Styled';

import { EnhancedModal } from 'components/Modal';
import { PDFAlert } from 'components/PDFAlert';
import { SignaturePad } from 'components/SignaturePad';
import { Button } from 'components/Button';
import { SignBadge } from 'components/SignBadge';

import { Coordinates, SignatureBoxInfo } from 'types/parameter';
import { SIGN_TYPES } from 'utills/constants';
import { getPositionY, signPDF, sanitizedPDFFileName } from 'utills/pdf';
import {
  getSignatureFromSession,
  setSignatureInSession,
  getValueFromSession,
  removeValueFromSession,
  setValueInSession,
} from 'utills/storage';
import { azurePDFAuth } from 'utills/azureRequestScript';
import useTheme from 'hooks/useTheme';

import RedBadge from 'assets/icons/signBoxRed.svg';
import OptionalBadge from 'assets/icons/optionalSignBadge.svg';

import styles from './PDFReader.module.scss';
import { SignatureSize } from 'enums.ts/SignatureSize';

interface PDFProps extends Styled {
  uncompletedSignature: boolean;
  file: any;
  unsignedBoxes: string[];
  setUnsignedBoxes(unsignedBoxes: string[]): void;
  alertNext?: any;
}
interface IOptionalInitalGroup {
  [key: string]: {
    option: string;
    signed: boolean;
  }[];
}

const Max_PDF_Width = 660;
const Adjust_Location_Date = 3;
const Adjust_Location = 3.5;
const Adjust_Location_Date_Signed = 4;
const Base_Font_Size = '0.5';
const Font_Size_SignBammer = '.58';
const Line_Height = '1.2';
const Adjust_Location_Date_Sign = 5;
const Widht_SignBadge = '7';
const Margin_Left_SignBanner = '.7';
const Margin_Left_Mobile = '2.2';
const Adjust_Location_Date_Top = 0.74;
const Adjust_Width_Date = 95;

const AzureStorageAccount = `https://${process.env.REACT_APP_AZURE_STORAGE_ACCOUNT}.blob.core.windows.net`;

export const PDFReader = forwardRef(
  (
    {
      uncompletedSignature,
      file,
      unsignedBoxes,
      setUnsignedBoxes,
      className,
      alertNext,
    }: PDFProps,
    ref
  ) => {
    //A forwardRef was added, in order to send the handleFinish method to the DocumentReview component to be executed by clicking on the Next button.
    const { themeName } = useTheme();
    const { width, height } = useViewport();
    const { pdf, setPdf } = usePDFContex();
    const navigate = useNavigate();
    const { addToast } = useToasts();
    const pdfViewWidth = width > Max_PDF_Width ? Max_PDF_Width : width;
    const [loading, setLoading] = useState<boolean>(false);
    const [uploading, setUploading] = useState<boolean>(false);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [mainSign, setMainSign] = useState<string | null>(null);
    const [signatureStatus, setSignatureStatus] = useState<SignatureBoxInfo[]>([]);
    const [numPages, setNumPages] = useState<number[]>([]);
    const [pdfData, setPdfData] = useState<PDFDocument | null>(null);
    const [pageWidth, setPageWidth] = useState<number>(0);
    const [pageHeight, setPageHeight] = useState<number>(0);
    const [selectedPage, setSelectedPage] = useState<number>(1);
    const [selectedForm, setSelectedForm] = useState<number>(0);
    const [isAlert, setIsAlert] = useState<boolean>(false);
    const signature = getSignatureFromSession();
    const typePrint = getValueFromSession('typePrint');
    const describePrint = getValueFromSession('describePrint');
    const [isTypeModalOpen, setIsTypeModalOpen] = useState<boolean>(false);
    const [typeName, setTypeName] = useState<string | null>(null);
    const [isDescribeModalOpen, setIsDescribeModalOpen] = useState<boolean>(false);
    const [optionalInitalGroup, setOptionalInitalGroup] =
      useState<IOptionalInitalGroup>({});
    const [pageOptional, setPageOptional] = useState<number>(0);
    const [formIndexOptional, setFormIndexOptional] = useState<number>(0);
    const todayDate = moment(new Date()).format('MM/DD/YY');
    //validation if the coordinate is optional it is set to false, if the coordinate is mandatory it must be validated with isAlert which is the flag for the optional array.
    const validationNoOptional = false;
    const handleDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
      setNumPages(new Array(numPages).fill(0).map((_, index) => index + 1));
    };
    const [isWrorSignatureRequired, setIsWrorSignatureRequired] =
      useState<boolean>(false);
    const [isWRORCustomerAcknowledgementAdded, setWRORCustomerAcknowledgementAdded] =
      useState(false);
    const [isWRORCustomerSignAdded, setIsWRORCustomerSignAdded] = useState(false);

    // method to find a substring in a string
    const checkString = (subString: string, mainString: string) => {
      var mainRegExp = RegExp(subString);
      if (mainRegExp.test(mainString)) {
        // we can skip locking fields by their fieldnames here
        return true;
      }
      return false;
    };
    // method to analyze the string to see if it is equal to customerInitials and Option , if found it retu//rns true
    const checkIfOptionBelongsToGroup = (mainString: string): boolean => {
      const containsCustomerInitials = checkString('customerInitials', mainString);
      const containsOption = checkString('Option', mainString);
      if (containsCustomerInitials && containsOption) {
        return true;
      }
      return false;
    };

    // method to find the group of the customerInitials coordinate, and store it in an array with the its option

    const getGroupAndOptionFromCoordinateType = (mainString: string) => {
      const separators = ['customerInitials', 'Option'];
      const optionalInitialArray = mainString.split(
        new RegExp(separators.join('|'), 'g')
      );
      optionalInitialArray.splice(0, 1);
      // the first position of array is the group
      // the first position of array is the optional value
      return { groupId: optionalInitialArray[0], optionId: optionalInitialArray[1] };
    };

    const handleOpenModal = (page: number, formIndex: number) => {
      let signatureInfo = signatureStatus.find((item: SignatureBoxInfo) => {
        if (item.pageIndex === page) {
          return true;
        } else {
          return false;
        }
      });

      if (
        signatureInfo &&
        checkIfOptionBelongsToGroup(signatureInfo.coordinates[formIndex].type)
      ) {
        setPageOptional(page);
        setFormIndexOptional(formIndex);
        const { groupId, optionId } = getGroupAndOptionFromCoordinateType(
          signatureInfo.coordinates[formIndex].type
        );
        let optionalInitalGroupAux = { ...optionalInitalGroup };
        // Iterate the optionalIntialsGroup state to set the signed option
        optionalInitalGroupAux[groupId] = optionalInitalGroupAux[groupId].map(
          (optional) => {
            if (optional.option === optionId) {
              if (signature.initial !== undefined) {
                optional.signed = true;
              } else {
                optional.signed = false;
              }
            } else {
              optional.signed = false;
            }
            return optional;
          }
        );
        setOptionalInitalGroup(optionalInitalGroupAux);
      }

      setSelectedForm(formIndex);
      setSelectedPage(page);
      if (
        signature.main ||
        (signatureInfo &&
          signatureInfo.coordinates[formIndex].type === 'tapTodayDate')
      ) {
        setSignatureStatus(signPDF(signatureStatus, page, formIndex));
      } else {
        setIsModalOpen(true);
      }
    };

    const handleOpenTypeModal = (page: number, formIndex: number) => {
      setSelectedForm(formIndex);
      setSelectedPage(page);
      setIsTypeModalOpen(true);
    };

    const handleOpenDescribeModal = (page: number, formIndex: number) => {
      setSelectedForm(formIndex);
      setSelectedPage(page);
      setIsDescribeModalOpen(true);
    };

    const handleCloseModal = () => {
      setIsModalOpen(false);
      setMainSign(null);
    };

    const handleSign = (sign: string): void => {
      if (mainSign) {
        setSignatureInSession({
          main: mainSign,
          initial: sign,
        });
        setSignatureStatus(signPDF(signatureStatus, selectedPage, selectedForm));
        handleCloseModal();
      } else {
        setMainSign(sign);
      }
    };

    const handleType = (type: string): void => {
      if (typeName) {
        setValueInSession('typePrint', {
          name: typeName,
          date: type,
        });
        setSignatureStatus(signPDF(signatureStatus, selectedPage, selectedForm));
        setSignatureStatus(signPDF(signatureStatus, selectedPage, selectedForm + 1));
        setIsTypeModalOpen(false);
      } else {
        setTypeName(type);
      }
    };

    const handleDescribe = (describe: string): void => {
      setValueInSession('describePrint', describe);
      setSignatureStatus(signPDF(signatureStatus, selectedPage, selectedForm));
      setIsDescribeModalOpen(false);
    };

    const readFile = useCallback(async () => {
      setLoading(true);
      if (typeof file === 'string') {
        fetch(file)
          .then((res) => res.arrayBuffer())
          .then((data) => PDFDocument.load(data))
          .then((data) => {
            const pages = data.getPages();
            setPageWidth(pages[0].getWidth());
            setPageHeight(pages[0].getHeight());
            setPdfData(data);
            setLoading(false);
          });
      } else {
        PDFDocument.load(file).then((data) => {
          const pages = data.getPages();
          setPageWidth(pages[0].getWidth());
          setPageHeight(pages[0].getHeight());
          setPdfData(data);
          setLoading(false);
        });
      }
    }, []);

    const uploadPDF = async (data: any) => {
      if (!data) {
        console.log('Data should not be null.');
        return;
      }
      const serviceOrder = getValueFromSession('serviceOrder');
      const clientname = getValueFromSession('clientname');
      const filename = sanitizedPDFFileName(
        clientname,
        moment(new Date()).format('MM-DD-YY')
      );
      const path = `/${process.env.REACT_APP_AZURE_SIGNED_SO_CONTAINER}/signed-service-order-${serviceOrder}.pdf`;
      const metaData = getValueFromSession('pdfMetaData') || {};
      const headers = {
        ...metaData,
        'x-ms-date': '',
        Authorization: '',
        'x-ms-version': '2021-04-10',
        'x-ms-blob-type': 'BlockBlob',
        'content-type': 'application/pdf',
        'content-length': data.byteLength,
        'x-ms-meta-signstatus': 'SIGNED-LATER',
        'x-ms-meta-signedpdfname': filename,
      };
      const auths = azurePDFAuth(headers, 'PUT', path);
      setUploading(true);
      setValueInSession('signedpdfname', filename);
      return fetch(
        `https://${process.env.REACT_APP_AZURE_STORAGE_ACCOUNT}.blob.core.windows.net${path}`,
        {
          method: 'PUT',
          body: data,
          headers: {
            ...headers,
            'x-ms-date': auths.headerDate,
            Authorization: auths.headerAuthorization,
          },
        }
      );
    };

    /**
     * Method to copy the PDF Metadata Json to the serviceorder container on Azure Blob this will trigger the SignedServiceOrderPostLines Logic App
     * @returns responce promise
     */
    const copyJsonOnCloud = async () => {
      const serviceOrder = getValueFromSession('serviceOrder');
      if (!serviceOrder) {
        console.log('ServiceOrder should not be null.');
        return;
      }

      const sourcePath = `/${process.env.REACT_APP_AZURE_UNSIGNED_SO_CONTAINER}/meta_postlines_${serviceOrder}.json`;
      const destinationPath = `/${process.env.REACT_APP_AZURE_SIGNED_SO_CONTAINER}/meta_postlines_${serviceOrder}.json`;

      const headers = {
        'x-ms-date': '',
        Authorization: '',
        'x-ms-version': '2021-04-10',
        'content-type': 'application/json',
        'x-ms-copy-source': `${AzureStorageAccount}${sourcePath}`,
      };
      const auths = azurePDFAuth(headers, 'PUT', destinationPath);
      setUploading(true);
      return fetch(`${AzureStorageAccount}${destinationPath}`, {
        method: 'PUT',
        headers: {
          ...headers,
          'x-ms-date': auths.headerDate,
          Authorization: auths.headerAuthorization,
        },
      });
    };

    /**
     * Upload the PDF and copy Json File, show congratulation page if success
     * @param pdf to be uploaded
     */
    const handleFilesOnAzure = async (pdf: any) => {
      const link = getValueFromSession('link') || '';
      try {
        copyJsonOnCloud();
      } catch {}
      uploadPDF(pdf)
        .then((res: any) => {
          const succeed = res.status < 400;
          if (succeed) {
            setPdf(pdf);
            navigate(`/congratulation?${link}`);
          } else {
            throw new Error();
          }
        })
        .catch(() => {
          addToast('An error occurred sending your documents, please try again.', {
            appearance: 'error',
            autoDismiss: true,
          });
          removeValueFromSession('signature');
          navigate(`/home?${link}`);
        })
        .finally(() => {
          setUploading(false);
        });
    };

    useImperativeHandle(ref, () => ({
      finishPDF() {
        handleFinish(); //This reference communicates to the DocumentReview component to use the handleFinish method.
      },
    }));

    const handleFinish = async () => {
      if (unsignedBoxes.length) {
        setIsAlert(true);
      } else {
        if (pdfData && signature.main && signature.initial) {
          const mainSignBytes = await fetch(signature.main).then((res) =>
            res.arrayBuffer()
          );
          const mainSignPng = await pdfData.embedPng(mainSignBytes);

          const initialSignBytes = await fetch(signature.initial).then((res) =>
            res.arrayBuffer()
          );
          const initialSignPng = await pdfData.embedPng(initialSignBytes);

          let customerNamePng: any = null;
          let todaysDatePng: any = null;
          let customerAcknowledgementPng: any = null;
          if (describePrint) {
            const customerAcknowledgementBytes = await fetch(describePrint).then(
              (res) => res.arrayBuffer()
            );
            customerAcknowledgementPng = await pdfData.embedPng(
              customerAcknowledgementBytes
            );
          }

          if (typePrint.name) {
            const customerNameBytes = await fetch(typePrint.name).then((res) =>
              res.arrayBuffer()
            );
            customerNamePng = await pdfData.embedPng(customerNameBytes);
          }
          if (typePrint.date) {
            const todaysDateBytes = await fetch(typePrint.date).then((res) =>
              res.arrayBuffer()
            );
            todaysDatePng = await pdfData.embedPng(todaysDateBytes);
          }

          const form = pdfData.getForm();

          signatureStatus.forEach((pageSignature: SignatureBoxInfo) => {
            const pageSignatures: Coordinates[] = pageSignature.coordinates;
            const page = pdfData.getPage(pageSignature.pageIndex - 1);
            pageSignatures.forEach((signatureCoordinate: Coordinates) => {
              if (signatureCoordinate.signed) {
                const signField = form.createTextField(signatureCoordinate.name);
                if (signatureCoordinate.type === 'tapTodayDate') {
                  signField.addToPage(page, {
                    x: signatureCoordinate.x,
                    y: signatureCoordinate.y + Adjust_Location_Date,
                    width: signatureCoordinate.width - Adjust_Location_Date_Signed,
                    height: signatureCoordinate.height - Adjust_Location_Date_Signed,
                    borderWidth: 0,
                  });
                } else {
                  signField.addToPage(page, {
                    x: signatureCoordinate.x + 25,
                    y: signatureCoordinate.y,
                    width: signatureCoordinate.width,
                    height: signatureCoordinate.height,
                  });
                }

                if (signatureCoordinate.type === SIGN_TYPES.main) {
                  signField.setImage(mainSignPng);
                } else if (signatureCoordinate.type === SIGN_TYPES.initial) {
                  signField.setImage(initialSignPng);
                } else if (signatureCoordinate.type === 'tapTodayDate') {
                  signField.setText(todayDate);
                } else if (signatureCoordinate.type === 'customerOptionallnitials') {
                  signField.setImage(initialSignPng);
                } else if (
                  signatureCoordinate.type === 'customerOptionalSignature'
                ) {
                  signField.setImage(mainSignPng);
                } else if (
                  signatureCoordinate.type === 'customerName' &&
                  customerNamePng
                ) {
                  signField.setImage(customerNamePng);
                } else if (
                  signatureCoordinate.type === 'todaysDate' &&
                  todaysDatePng
                ) {
                  signField.setImage(todaysDatePng);
                } else if (
                  signatureCoordinate.type === 'customerAcknowledgement' &&
                  customerAcknowledgementPng
                ) {
                  signField.setImage(customerAcknowledgementPng);
                } else if (
                  signatureCoordinate.type ===
                    'customerSignature1DependentcustomerAcknowledgementHolder' &&
                  customerAcknowledgementPng
                ) {
                  signField.setImage(mainSignPng);
                } else if (checkIfOptionBelongsToGroup(signatureCoordinate.type)) {
                  const { groupId, optionId } = getGroupAndOptionFromCoordinateType(
                    signatureCoordinate.type
                  );
                  const optionSelected = optionalInitalGroup[groupId].find(
                    (groupOption) => groupOption.option === optionId
                  );
                  if (optionSelected?.signed) {
                    signField.setImage(initialSignPng);
                  }
                }
                signField.enableReadOnly();
              }
            });
          });
          form.flatten();
          const pdfBytes = await pdfData.save();
          await handleFilesOnAzure(pdfBytes);
        } else if (!signatureStatus.length) {
          await handleFilesOnAzure(pdf);
        }
      }
    };

    useEffect(() => {
      if (uncompletedSignature) {
        setIsAlert(false);
      } else {
        setIsAlert(alertNext);
      }
    }, [uncompletedSignature]);

    useEffect(() => {
      if (!pageWidth) {
        readFile();
      }
      return () => {
        setIsAlert(false);
      };
    }, [pageWidth]);

    useEffect(() => {
      return setIsAlert(alertNext);
    }, [alertNext]);

    /**
     * Get the signature from session(this are set from the metadata of the PDF)
     */
    useEffect(() => {
      if (pdfData) {
        const signatures = getValueFromSession('signatures');
        const pdfSignBoxes: SignatureBoxInfo[] = [];
        JSON.parse(signatures).forEach((page: any) => {
          const coordinates: Coordinates[] = [];
          page.coordinates.map((coordinate: any, index: number) => {
            if (
              coordinate.type === 'Customer' ||
              coordinate.type === 'customerOptionallnitials' ||
              coordinate.type === 'customerOptionalSignature' ||
              coordinate.type === 'CustomerInitial' ||
              coordinate.type === 'customerName' ||
              coordinate.type === 'todaysDate' ||
              coordinate.type === 'customerAcknowledgement' ||
              coordinate.type ===
                'customerSignature1DependentcustomerAcknowledgementHolder' ||
              coordinate.type === 'tapTodayDate' ||
              checkIfOptionBelongsToGroup(coordinate.type)
            ) {
              coordinates.push({
                ...coordinate,
                signed: false,
                name: `${coordinate.type}-${page.pageIndex + 1}-${index}`,
                type: coordinate.type,
              });
            }

            if (
              coordinate.type ===
              'customerSignature1DependentcustomerAcknowledgementHolder'
            ) {
              setIsWrorSignatureRequired(true);
            }
          });

          pdfSignBoxes.push({
            pageIndex: page.pageIndex + 1,
            coordinates,
          });
        });

        setSignatureStatus(pdfSignBoxes);

        if (pdfSignBoxes) {
          const optionalInitalGroupObject: any = {};
          pdfSignBoxes.forEach((pageCoordinates: SignatureBoxInfo) => {
            // Iterate all the coordinates
            pageCoordinates.coordinates.forEach((coordinate) => {
              // Check if the type of the coordinate is a option that belongs to a group
              const isCordinateOptionOfGroup = checkIfOptionBelongsToGroup(
                coordinate.type
              );
              if (isCordinateOptionOfGroup) {
                // Get the groupID and optionID
                const { groupId, optionId } = getGroupAndOptionFromCoordinateType(
                  coordinate.type
                );
                // set the group and options in the object state
                if (optionalInitalGroupObject[groupId]) {
                  optionalInitalGroupObject[groupId].push({
                    option: optionId,
                    signed: false,
                  });
                } else {
                  optionalInitalGroupObject[groupId] = [
                    {
                      option: optionId,
                      signed: false,
                    },
                  ];
                }
              }
            });
          });
          //set the state
          setOptionalInitalGroup({ ...optionalInitalGroupObject });
        }
      }
    }, [pdfData]);

    useEffect(() => {
      const temp: string[] = [];
      if (signatureStatus.length) {
        signatureStatus.forEach((item) => {
          item.coordinates.forEach((coordinate) => {
            if (
              isWrorSignatureRequired &&
              coordinate.type === 'customerAcknowledgement' &&
              coordinate?.signed
            ) {
              setWRORCustomerAcknowledgementAdded(true);
              const customerAcknowledgement = item.coordinates.find(
                (val) =>
                  val.type ===
                  'customerSignature1DependentcustomerAcknowledgementHolder'
              );
              if (customerAcknowledgement && !customerAcknowledgement.signed) {
                temp.push(customerAcknowledgement.name);
              }
            }

            if (
              isWrorSignatureRequired &&
              coordinate.type ===
                'customerSignature1DependentcustomerAcknowledgementHolder' &&
              coordinate?.signed
            ) {
              setIsWRORCustomerSignAdded(true);
              const customerSign = item.coordinates.find(
                (val) => val.type === 'customerAcknowledgement'
              );
              if (customerSign && !customerSign.signed) {
                temp.push(customerSign.name);
              }
            }

            if (checkIfOptionBelongsToGroup(coordinate.type)) {
              const { groupId } = getGroupAndOptionFromCoordinateType(
                coordinate.type
              );
              const groupHaveOptionSigned =
                optionalInitalGroup[groupId] &&
                optionalInitalGroup[groupId].some(
                  (groupOption) => groupOption.signed
                );
              if (!groupHaveOptionSigned) {
                temp.push(coordinate.name);
              }
            } else if (
              !coordinate.signed &&
              coordinate.type !== 'todaysDate' &&
              coordinate.type !== 'customerOptionallnitials' &&
              coordinate.type !== 'customerOptionalSignature' &&
              coordinate.type !== 'customerAcknowledgement' &&
              coordinate.type !==
                'customerSignature1DependentcustomerAcknowledgementHolder'
            ) {
              temp.push(coordinate.name);
            }
          });
        });
      }

      setUnsignedBoxes(temp);
    }, [signatureStatus]);

    useEffect(() => {
      if (signature.initial !== undefined) {
        handleOpenModal(pageOptional, formIndexOptional);
      }
    }, [signature.initial]);

    const handleAlertClick = () => {
      if (unsignedBoxes.length) {
        document.getElementById(unsignedBoxes[0])?.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'center',
        });
      }
    };

    if (loading && pageWidth) return <div />;

    if (uploading) return <div>Uploading signed document...</div>;

    return (
      <div className={className}>
        <Document file={file} onLoadSuccess={handleDocumentLoadSuccess}>
          {numPages.map((page) => {
            const coordinatesSignaturePerPage: SignatureBoxInfo | undefined =
              signatureStatus.find((sign) => sign.pageIndex === page);
            return (
              <div
                key={page}
                id={`toc-page-${page}`}
                className={styles.pageContainer}
              >
                <Page
                  width={pdfViewWidth}
                  pageNumber={page}
                  renderTextLayer={false}
                  renderAnnotationLayer={false}
                />

                {!coordinatesSignaturePerPage ? null : (
                  <div
                    style={{
                      position: 'absolute',
                      top: 0,
                      height: '100%',
                      width: '100%',
                      left: 0,
                    }}
                  >
                    {coordinatesSignaturePerPage.coordinates.map(
                      (coordItem: Coordinates, coordsIndex: number) => {
                        let imageUrlSignHere = `url(${
                          isAlert ? RedBadge : `images/signBox${themeName}.svg`
                        })`;
                        if (checkIfOptionBelongsToGroup(coordItem.type)) {
                          const { groupId, optionId } =
                            getGroupAndOptionFromCoordinateType(coordItem.type);
                          const optionSelected = optionalInitalGroup[groupId].find(
                            (groupOption) => groupOption.option === optionId
                          );
                          const isGroupsigned = optionalInitalGroup[groupId].some(
                            (groupOption) => groupOption.signed
                          );
                          coordItem.signed = optionSelected?.signed ?? false;
                          imageUrlSignHere = `url(${
                            isAlert && !isGroupsigned
                              ? RedBadge
                              : `images/signBox${themeName}.svg`
                          })`;
                        }

                        //Validation so that it is not painted red because it is optional.
                        if (coordItem.type === 'customerOptionallnitials') {
                          imageUrlSignHere = `url(${
                            validationNoOptional
                              ? RedBadge
                              : `images/signBox${themeName}.svg`
                          })`;
                        }
                        //Validation so that it is not painted red because it is optional.
                        if (coordItem.type === 'customerOptionalSignature') {
                          imageUrlSignHere = `url(${
                            validationNoOptional
                              ? RedBadge
                              : `images/signBox${themeName}.svg`
                          })`;
                        }
                        const positionY = getPositionY(
                          coordItem,
                          pageHeight,
                          pageWidth,
                          pdfViewWidth
                        );
                        const signBoxHeight =
                          (coordItem.height / pageWidth) * pdfViewWidth -
                          (width < 576 ? 6 : 14);

                        if (
                          coordItem.type === 'customerName' ||
                          coordItem.type === 'todaysDate'
                        ) {
                          return coordItem.signed ? (
                            <img
                              key={`${coordsIndex}`}
                              src={
                                coordItem.type === 'customerName'
                                  ? typePrint.name
                                  : typePrint.date
                              }
                              style={{
                                position: 'absolute',
                                top: positionY - 8,
                                left:
                                  ((coordItem.x + coordItem.width / 2) / pageWidth) *
                                  pdfViewWidth,
                                height:
                                  (coordItem.height / pageWidth) * pdfViewWidth - 12,
                                transform: 'translate(-50%, 4px)',
                              }}
                            />
                          ) : coordItem.type === 'customerName' ? (
                            <SignBadge
                              key={`${coordsIndex}`}
                              id={coordItem.name}
                              title="TAP HERE"
                              style={{
                                position: 'absolute',
                                top:
                                  positionY +
                                  ((coordItem.height / pageWidth) * pdfViewWidth) /
                                    2,
                                left: (coordItem.x / pageWidth) * pdfViewWidth - 15,
                                width: (126 * signBoxHeight) / 40,
                                height: signBoxHeight - 5,
                                backgroundImage: `url(${
                                  isAlert
                                    ? RedBadge
                                    : `images/signBox${themeName}.svg`
                                })`,
                                userSelect: 'none',
                              }}
                              onClick={() => {
                                handleOpenTypeModal(page, coordsIndex);
                              }}
                              fontStyle={
                                coordItem.width < 150
                                  ? {
                                      fontSize:
                                        width < 650 ? (width < 460 ? 8 : 11) : 12,
                                      paddingRight: 4,
                                      lineHeight: '8px',
                                      fontWeight: 'normal',
                                    }
                                  : undefined
                              }
                            />
                          ) : null;
                        }

                        //Validation so that it is not painted red because it is optional.
                        if (
                          coordItem.type ===
                          'customerSignature1DependentcustomerAcknowledgementHolder'
                        ) {
                          imageUrlSignHere = `url(${
                            isWrorSignatureRequired
                              ? isWRORCustomerAcknowledgementAdded
                                ? isAlert
                                  ? RedBadge
                                  : `images/signBox${themeName}.svg`
                                : OptionalBadge
                              : `images/signBox${themeName}.svg`
                          })`;
                        }

                        if (coordItem.type === 'customerAcknowledgement') {
                          return coordItem.signed ? (
                            <img
                              key={`${coordsIndex}`}
                              src={describePrint}
                              style={{
                                position: 'absolute',
                                top: positionY,
                                left:
                                  ((coordItem.x + coordItem.width / 2) / pageWidth) *
                                  pdfViewWidth,
                                width:
                                  pdfViewWidth < Max_PDF_Width
                                    ? pdfViewWidth - coordItem.width
                                    : coordItem.width,
                                height:
                                  (coordItem.height / pageWidth) * pdfViewWidth - 12,
                                transform: 'translate(-50%, 4px)',
                              }}
                            />
                          ) : (
                            //Validation so that it is not painted red because it is optional.
                            <SignBadge
                              key={`${coordsIndex}`}
                              id={coordItem.name}
                              title="TAP HERE"
                              style={{
                                position: 'absolute',
                                top:
                                  positionY +
                                  (coordItem.height + pdfViewWidth) /
                                    (Adjust_Location_Date * 7),
                                left: (coordItem.x / pageWidth) * pdfViewWidth + 10,
                                width: 120,
                                height: 60,
                                backgroundImage: `url(${
                                  isWrorSignatureRequired
                                    ? isWRORCustomerSignAdded
                                      ? isAlert
                                        ? RedBadge
                                        : `images/signBox${themeName}.svg`
                                      : `images/signBox${themeName}.svg`
                                    : validationNoOptional
                                    ? RedBadge
                                    : `images/signBox${themeName}.svg`
                                })`,
                                userSelect: 'none',
                              }}
                              onClick={() => {
                                handleOpenDescribeModal(page, coordsIndex);
                              }}
                              fontStyle={
                                coordItem.width < 150
                                  ? {
                                      fontSize:
                                        width < 650 ? (width < 460 ? 8 : 11) : 12,
                                      paddingRight: 4,
                                      lineHeight: '8px',
                                      fontWeight: 'normal',
                                    }
                                  : {
                                      fontSize: 14,
                                      fontWeight: 'normal',
                                    }
                              }
                            />
                          );
                        }

                        if (coordItem.type === 'tapTodayDate') {
                          return coordItem.signed ? (
                            <div
                              key={`${coordsIndex}`}
                              style={{
                                position: 'absolute',
                                top:
                                  pdfViewWidth < Max_PDF_Width
                                    ? positionY - Adjust_Location
                                    : positionY -
                                      height / Adjust_Location_Date_Top / positionY,
                                left:
                                  pdfViewWidth < Max_PDF_Width
                                    ? (coordItem.width / 2 / pageWidth) *
                                        pdfViewWidth -
                                      10
                                    : ((coordItem.x + coordItem.width / 2) /
                                        pageWidth) *
                                        pdfViewWidth -
                                      Adjust_Width_Date,
                                height:
                                  (coordItem.height / pageWidth) * pdfViewWidth - 8,
                                transform: 'translate(-50%, 4px)',
                                fontSize:
                                  pdfViewWidth < Max_PDF_Width
                                    ? `${pdfViewWidth / 1000}rem`
                                    : `${Base_Font_Size}em`,
                              }}
                            >
                              {todayDate}
                            </div>
                          ) : (
                            <SignBadge
                              key={`${coordsIndex}`}
                              id={coordItem.name}
                              title={"TAP TO ADD TODAY'S DATE"}
                              style={{
                                position: 'absolute',
                                top:
                                  positionY +
                                  ((coordItem.height / pageWidth) * pdfViewWidth) /
                                    2,
                                left:
                                  pdfViewWidth < Max_PDF_Width
                                    ? `${Margin_Left_Mobile}rem`
                                    : (coordItem.x / pageWidth) * pdfViewWidth -
                                      Adjust_Location_Date_Sign *
                                        Adjust_Location_Date_Sign,
                                width:
                                  pdfViewWidth < Max_PDF_Width
                                    ? pdfViewWidth / Adjust_Location_Date_Sign
                                    : `${Widht_SignBadge}em`,
                                height:
                                  pdfViewWidth < Max_PDF_Width
                                    ? pdfViewWidth / (Adjust_Location_Date_Sign * 3)
                                    : `${Widht_SignBadge}em`,
                                backgroundImage: imageUrlSignHere,
                                userSelect: 'none',
                              }}
                              onClick={() => {
                                handleOpenModal(page, coordsIndex);
                              }}
                              fontStyle={
                                pdfViewWidth < Max_PDF_Width
                                  ? {
                                      fontSize: `${pdfViewWidth / 1000}rem`,
                                      lineHeight: `${Line_Height}em`,
                                      marginLeft: `${Margin_Left_SignBanner}em`,
                                    }
                                  : {
                                      fontSize: `${Font_Size_SignBammer}em`,
                                      marginLeft: `${Margin_Left_SignBanner}em`,
                                    }
                              }
                            />
                          );
                        }
                        return coordItem.signed ? (
                          <img
                            key={`${coordsIndex}`}
                            src={
                              coordItem.type === SIGN_TYPES.main ||
                              coordItem.type ===
                                SIGN_TYPES.customerOptionalSignature ||
                              coordItem.type ===
                                SIGN_TYPES.customerOptionalSignature ||
                              coordItem.type ===
                                SIGN_TYPES.customerSignature1Dependent
                                ? signature.main
                                : signature.initial
                            }
                            style={{
                              position: 'absolute',
                              top: positionY,
                              left:
                                ((coordItem.x + coordItem.width / 2) / pageWidth) *
                                pdfViewWidth,
                              height:
                                (coordItem.height / pageWidth) * pdfViewWidth - 8,
                              transform: 'translate(-50%, 4px)',
                            }}
                          />
                        ) : (
                          <SignBadge
                            key={`${coordsIndex}`}
                            id={coordItem.name}
                            title={
                              coordItem.type === SIGN_TYPES.main ||
                              coordItem.type ===
                                SIGN_TYPES.customerOptionalSignature ||
                              coordItem.type ===
                                SIGN_TYPES.customerSignature1Dependent
                                ? 'Sign Here'
                                : 'Initial Here'
                            }
                            style={{
                              position: 'absolute',
                              top:
                                positionY +
                                ((coordItem.height / pageWidth) * pdfViewWidth) / 2,
                              left:
                                isWrorSignatureRequired &&
                                coordItem.type ===
                                  'customerSignature1DependentcustomerAcknowledgementHolder'
                                  ? (coordItem.x / pageWidth) * pdfViewWidth - 6
                                  : (coordItem.x / pageWidth) * pdfViewWidth + 10,
                              width:
                                coordItem.sigSize === SignatureSize.Small
                                  ? '5em'
                                  : (126 * signBoxHeight) / 40,
                              height:
                                coordItem.sigSize === SignatureSize.Small
                                  ? '1em'
                                  : signBoxHeight,
                              backgroundImage: imageUrlSignHere,
                              userSelect: 'none',
                            }}
                            onClick={() => {
                              handleOpenModal(page, coordsIndex);
                            }}
                            fontStyle={
                              coordItem.width < 150
                                ? {
                                    fontSize:
                                      coordItem.sigSize === SignatureSize.Small
                                        ? '.45em'
                                        : width < 650
                                        ? width < 460
                                          ? 8
                                          : 11
                                        : 12,
                                    paddingRight: 4,
                                    lineHeight: '8px',
                                    fontWeight: 'normal',
                                  }
                                : undefined
                            }
                          />
                        );
                      }
                    )}
                  </div>
                )}
              </div>
            );
          })}
          <div className={styles.actions}>
            <Button onClick={handleFinish}>Finish</Button>
          </div>
        </Document>
        <EnhancedModal
          title={mainSign ? 'Add Your Initials' : 'Add Your Signature'}
          isModalOpen={isModalOpen}
          closeModal={handleCloseModal}
        >
          <SignaturePad
            title={mainSign ? 'ACCEPT AND SAVE' : 'ACCEPT AND NEXT'}
            note="Use your mouse to create your signature"
            handleSign={handleSign}
          />
        </EnhancedModal>
        <EnhancedModal
          title={typeName ? "Today's Date" : 'Print Your Name'}
          isModalOpen={isTypeModalOpen}
          closeModal={() => setIsTypeModalOpen(false)}
        >
          <SignaturePad
            title={typeName ? 'ACCEPT AND SAVE' : 'ACCEPT AND NEXT'}
            note=""
            handleSign={handleType}
          />
        </EnhancedModal>
        <EnhancedModal
          title={'Describe in your handwriting'}
          isModalOpen={isDescribeModalOpen}
          isLarge
          closeModal={() => setIsDescribeModalOpen(false)}
        >
          <SignaturePad
            title={'ACCEPT AND SAVE'}
            note=""
            isLarge
            handleSign={handleDescribe}
          />
        </EnhancedModal>
        <PDFAlert
          isModalOpen={isAlert}
          description={
            unsignedBoxes.length
              ? `Missing Signatures (${unsignedBoxes.length})`
              : 'Success! Your document is ready.'
          }
          isError={!!unsignedBoxes.length}
          onShow={handleAlertClick}
        />
      </div>
    );
  }
);
